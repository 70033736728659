<template>
  <single-page>
    <article v-if="lang=='zh-CN'" lang="zh-CN">
      <h1>常见问题解答</h1>
      <section>
        <h2>MEKE是什么？</h2>
        <p>答：MEKE是一款区块链技术构建的去中心化加密货币衍生品交易协议，可用于BTC、ETH等各类主流加密货币的永续合约交易。不同于传统中心化永续合约交易所，MEKE的主要功能都部署在链上，用户的每笔交易都会上链，保证了交易的真实性。同时，由于所有交易上链，用户的每笔交易都可追踪，确保了用户交易的公平性；交易程序由智能合约执行，用户的资产安全由区块链提供保证，整个过程资产没有第三方干预，资产完全由用户掌控。</p>
      </section>
      <section>
        <h2>什么是永续合约？</h2>
        <p>答：合约交易是一种先不用交易实物，规定在某个固定的期限后，可以用固定的价格进行买卖的一种金融产品。</p>
        <p>永续合约由传统合约演化而来，传统合约是固定期限（如一周、一月、一季度或年等）进行一次合约交割。永续合约的特点是没有固定期限，随时都可以进行合约交易的一种加密货币衍生产品。</p>
      </section>
      <section>
        <h2>MEKE去中心化永续合约交易安全吗？</h2>
        <p>答：去中心化应用除非本身有漏洞，如果没有，那就是基本安全的。MEKE已经过全球知名安全审计公司certik进行审计，代码安全合格。但科技是不断发展的，不能保证一些无法预料的事情及不可抗力对安全产生影响。</p>
      </section>
      <section>
        <h2>MEKE有哪些优势？</h2>
        <p>答：在技术上：MEKE的主要功能都部署在链上，用户的每笔交易都会上链，保证了交易的真实性。同时，由于所有交易上链，用户的每笔交易都可追踪，确保了用户交易的公平性；交易程序由智能合约执行，用户的资产安全由区块链提供保证，整个过程资产没有第三方干预，资产完全由用户掌控。</p>
        <p>使用上：MEKE在页面布局上以及在操作体验上有着和中心化交易所一样的优质体验。</p>
      </section>
      <section>
        <h2>MEKE有什么发展前景？</h2>
        <p>答：当前整个加密货币市场每天的合约交易量达到1000亿-2000亿美金，每天产生的手续费利润近亿美金。而且这仅仅只是开始。</p>
        <p>在这么大的蛋糕中，MEKE只需要占到市场的1%，每日就会有上百万美金的收益。市场前景相当巨大。</p>
      </section>
      <section>
        <h2>MEKE可交易现货吗?</h2>
        <p>答：目前不可以，未来会考虑。</p>
      </section>
      <section>
        <h2>MEKE通证有什么用？</h2>
        <p>答：MEKE是MEKE去中心化合约交易所的平台通证。</p>
        <p>可用来 1、平台的DAO治理 2、持有MEKE减免交易手续费 3、平台对MEKE不定期的回购  4、抵押MEKE共享MEKE平台手续费。5、未来MEKE开放公链，MEKE可直接用于公链的Gas费</p>
      </section>
      <section>
        <h2>MEKE的维持保证金率是多少？</h2>
        <p>答：MEKE的维持保证金率是0.3%，即当保证金余额≤0.3%*开仓总价值时，仓位将被清算。</p>
      </section>
      <section>
        <h2>清算后的保证金怎么分配？</h2>
        <p>答：当被清算账户还有剩余保证金时，该数量一部分会分配给清算者，用以鼓励用户给其他爆仓的用户清算；另一部分将作为MEKE的保险基金，用于维护系统安全及突发事件。</p>
      </section>
      <section>
        <h2>怎样降低被清算风险？</h2>
        <p>答：尽量开小倍数的杠杆，保证保证金余额的充足。</p>
      </section>
    </article>
    <article v-else lang="en-US">
      <h1>Frequently asked questions</h1>
      <section>
        <h2>What is MEKE?</h2>
        <p>A: MEKE is a blockchain technology-based decentralized cryptocurrency derivatives trading platform that supports perpetual contract trading of mainstream cryptocurrencies such as BTC and ETH. Different from traditionally centralized perpetual contract exchanges, MEKE has its main functions deployed on the chain, and users can trade on the chain to ensure the authenticity of the transaction. Meanwhile, as users trade on the chain, each transaction can be traceable to ensure the fairness; the transaction procedure is controlled by smart contracts, and the safety of users' assets is assured by the blockchain. In the entire process of trading, there is no intervention from a third party, and assets are completely under the control of users.</p>
      </section>
      <section>
        <h2>What is a perpetual contract?</h2>
        <p>A: Contract trading is a kind of financial product that can be traded at a fixed price and at a fixed time, and in which no physical objects are traded at the beginning. </p>
        <p>The perpetual contract is evolved from the traditional contract, which has a fixed term (such as a week, month, quarter or year, etc.)and is settled all at once. A perpetual contract is a kind of cryptocurrency derivative that has no fixed term and can be traded at any time.</p>
      </section>
      <section>
        <h2>Is MEKE decentralized perpetual exchange safe?</h2>
        <p>A: Unless there are loopholes in the decentralized application itself, if not, it is basically safe. MEKE has been audited by certik, a world-renowned security audit company, and the code security was verified. However, technology is constantly evolving, and there is no guarantee that some unforeseen events and force majeure will have an impact on safety.</p>
      </section>
      <section>
        <h2>What are the advantages of MEKE?</h2>
        <p>A: Technically, MEKE has its main functions deployed on the chain, and users can trade on the chain to ensure the authenticity of the transaction. Meanwhile, as users trade on the chain, each transaction can be traceable to ensure the fairness; the transaction procedure is controlled by smart contracts, and the safety of users' assets is assured by the blockchain. In the entire process of trading, there is no intervention from a third party, and assets are completely under the control of users.</p>
        <p>In terms of use, MEKE has the same high-quality experience as the centralized exchange in terms of page layout and operational experience.</p>
      </section>
      <section>
        <h2>How about the market prospect of MEKE?</h2>
        <p>A: Currently, the entire cryptocurrency market presents a contract trading volume of US$100-200 billion per day, and the commission of about 100 million per day. And this is just the beginning.</p>
        <p>In such a big cake, MEKE only needs to occupy 1% of the market share to get millions of dollars of returns. The market prospect is quite huge.</p>
      </section>
      <section>
        <h2>Does MEKE support spot trading?</h2>
        <p>A: Not at the moment, but we may offer it in the future.</p>
      </section>
      <section>
        <h2>What is the function of MEKE token?</h2>
        <p>A: MEKE is the platform token of MEKE’s decentralized contract exchange.</p>
        <p>Its function include: 1. DAO governance of the platform 2. Reduced commission by holding MEKE 3. repurchase of MEKE by the platform at irregular intervals 4. sharing the commission by mortgaging MEKE. 5.as for the future public blockchain, MEKE coin can be directly used to offset the Gas fee charged  </p>
      </section>
      <section>
        <h2>What is the maintenance margin rate of MEKE?</h2>
        <p>A: The maintenance margin rate of MEKE is 0.3%, that is, when the margin balance is ≤0.3%*total value of opening position, the position will be liquidated.</p>
      </section>
      <section>
        <h2>How to distribute the remaining margin after liquidation?</h2>
        <p>A: When there is remaining margin in the liquidated account, part of the amount will be allocated to the liquidator to encourage users to liquidate for other liquidated users; the other part will be allocated to MEKE's insurance fund for maintaining system security and responding to emergencies event.</p>
      </section>
      <section>
        <h2>How can I reduce the risk of being liquidated?</h2>
        <p>A: Try to keep a small leverage multiplier and make sure that the margin balance is sufficient.</p>
      </section>
    </article>
  </single-page>
</template>

<script>
import SinglePage from './components/singlePage'
export default {
  components: {
    SinglePage
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    }
  }
};
</script>
<style lang="less" scoped></style>